import * as utils from '../../shared/utils.js';

export default {
  name: 'utils',
  proto: {
    utils,
  },
  static: {
    utils,
  },
};
